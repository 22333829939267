import React from "react"

import * as styles from "../styles/banner.module.scss"

const Banner = ({ bannerText, bannerDate }) => {
  return (
    <div id={styles.container}>
      <div>{bannerText}</div>
      <div>
        <small>{bannerDate}</small>
      </div>
    </div>
  )
}

export default Banner
