import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Header from "./header"
import Footer from "./footer"

import * as styles from "../styles/layout.module.scss"

const Layout = props => {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          author
          description
          title
          copyright
        }
      }
    }
  `)

  const siteMeta = data.site.siteMetadata

  return (
    <div className={styles.container}>
      <Header siteMeta={siteMeta} headOneOfPage={props.headOneOfPage} />
      <div className={styles.content}> {props.children}</div>
      <Footer siteMeta={siteMeta} />
    </div>
  )
}

export default Layout
