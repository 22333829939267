import React, { useState } from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import { GiHamburgerMenu } from "@react-icons/all-files/gi/GiHamburgerMenu"

import { AiOutlineCloseCircle } from "@react-icons/all-files/ai/AiOutlineCloseCircle"

import * as style from "../styles/nav.module.scss"

// It is importatnt to place these declarations here, because of the Timeout function
let opened = false
let timer

const NavTop = () => {
  const [hambOpened, setHambOpened] = useState(false)

  // Close the burger menu after 5 seconds if the user doesn't do that
  const hamb = () => {
    opened = !opened
    if (opened) {
      setHambOpened(true)
      timer = setTimeout(function () {
        clearTimeout(timer)
        opened = false
        setHambOpened(false)
      }, 5000)
    } else {
      clearTimeout(timer)
      setHambOpened(false)
    }
  }

  return (
    <nav className={style.container}>
      {hambOpened ? (
        <div></div>
      ) : (
        <div>
          <StaticImage width={60} src="../images/logo.png" />
        </div>
      )}

      <ul>
        <div id={hambOpened ? style.menuResponsive : style.menuNormal}>
          <Link activeClassName={style.activeMenu} to="/">
            Főoldal
          </Link>
          <Link
            activeClassName={style.activeMenu}
            to="/kovacs-gabi-bemutatkozas/"
          >
            Bemutatkozom
          </Link>
          <Link
            activeClassName={style.activeMenu}
            to="/fitnesz-szolgaltatasok/"
          >
            Szolgáltatások
          </Link>
          <Link activeClassName={style.activeMenu} to="/gabi-fitnesz-blog/">
            Blog
          </Link>
          <Link activeClassName={style.activeMenu} to="/calendar/">
            Naptár
          </Link>
          <Link activeClassName={style.activeMenu} to="/gabi-rolam-mondtak/">
            Vélemények
          </Link>
          <Link activeClassName={style.activeMenu} to="/contactme/">
            Kapcsolat
          </Link>
        </div>

        {/* Hamburger menu for mobile view */}
        <div onClick={e => hamb()} id={style.hamburger}>
          {hambOpened ? (
            <AiOutlineCloseCircle size="1x" />
          ) : (
            <GiHamburgerMenu size="1x" />
          )}
        </div>
      </ul>
    </nav>
  )
}

export default NavTop
