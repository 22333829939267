import React from "react"

import NavTop from "./navtop"
import Banner from "../components/banner"
import * as styles from "../styles/header.module.scss" //There is a problem without "* as"

const Header = ({ headOneOfPage }) => {
  let months = {
    0: "január",
    1: "február",
    2: "március",
    3: "április",
    4: "május",
    5: "június",
    6: "július",
    7: "augusztus",
    8: "szeptember",
    9: "október",
    10: "november",
    11: "december",
  }

  let days = {
    0: "Vasárnap",
    1: "Hétfő",
    2: "Kedd",
    3: "Szerda",
    4: "Csütörtök",
    5: "Péntek",
    6: "Szombat",
  }

  let mainDate = new Date()
  let thisYear = mainDate.getFullYear()
  let thisMonth = mainDate.getMonth()
  let thisDate = mainDate.getDate()
  let thisDay = mainDate.getDay()

  return (
    <header>
      <h1 className={styles.headOneOfPage}>{headOneOfPage}</h1>
      <Banner
        bannerText={`Üdvözöllek!  Mozogj velem ma is!`}
        bannerDate={`${thisYear}  ${months[thisMonth]} ${thisDate} - ${days[thisDay]}`}
      />
      <NavTop />
    </header>
  )
}

export default Header
