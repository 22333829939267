import React from "react"
import NavBottom from "./navbottom"

import * as style from "../styles/footer.module.scss" //There is a problem without "* as"

const Footer = ({ siteMeta }) => {
  return (
    <footer className={style.container}>
      <NavBottom />
      <div id={style.footerDetails}>
        <div id={style.copyright}> {siteMeta.copyright}</div>
        <div id={style.developer}>Készítette: {siteMeta.author}</div>
      </div>
    </footer>
  )
}

export default Footer
