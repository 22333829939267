import React from "react"
import { Link } from "gatsby"

import * as style from "../styles/navbottom.module.scss"

const NavBottom = () => {
  return (
    <nav className={style.container}>
      <ul>
        <Link activeClassName={style.activeMenu} to="/privacy/">
          Adatvédelem
        </Link>
        <Link activeClassName={style.activeMenu} to="/terms/">
          ÁSZF
        </Link>
      </ul>
    </nav>
  )
}

export default NavBottom
